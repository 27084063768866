<template>
	<div class="flex flex-wrap justify-center sm:-mx-2">
		<div class="w-full w-initial sm:pl-2 sm:pr-1 mb-4 sm:mb-0 flex">
			<slot name="left" />
		</div>
		<div class="w-full w-initial sm:pr-2 sm:pl-1 flex">
			<slot name="right" />
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HorizontalButtons'
});
</script>
<style lang="scss" scoped>
@media (min-width: $sm) {
	.w-initial {
		width: initial;
	}
}
</style>
